:root {
  --heightMiniaturizeHeader: 100px;
  --showMiddleColumnHeader: 56dvw;
  --showMiddleOpacityColumnHeader: 0;
  --widthImgColumnHeader: 120%;
  --paddingInlineRowHeader: 0;
  --columnGapRowHeader: 0;
  --maxWidthHeader: 76px;
}

/* Larger than tablet */
@media (min-width: 750px) {
  :root {
    --maxWidthHeader: 150px;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  :root {
    --columnGapRowHeader: 20px;
    --showMiddleOpacityColumnHeader: 1;
    --heightMiniaturizeHeader: 130px;
    --paddingInlineRowHeader: 20rem;
  }
}

#header {
  container-type: inline-size;
  position: fixed;
  z-index: 99;
  background-color: transparent;
  height: 0;
  display: flex;
  justify-content: center;
  width: 100dvw;

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100dvw;
    max-width: 1920px;
    padding-inline: var(--paddingInlineRowHeader);
    column-gap: var(--columnGapRowHeader);
  }

  .column {
    text-align: -moz-center;
    text-align: -webkit-center;

    img {
      width: auto;
      max-width: var(--maxWidthHeader);
      transition: all 0.2s ease-in;
    }
  }

  .hamburger-wrapper {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: var(--heightMiniaturizeHeader);
    justify-content: flex-end;
    margin-right: min(40px, 100%) !important;
  }

  .show {
    width: var(--showMiddleColumnHeader);
    opacity: var(--showMiddleOpacityColumnHeader);
  }
}

.miniaturize {
  background-color: rgba(#9fbbd1, 0.9) !important;
  height: var(--heightMiniaturizeHeader) !important;
  transition: height 0.3s ease-in, background-color 0.1s ease-in;

  box-shadow: -1px 5px 11px 1px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: -1px 5px 11px 1px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: -1px 5px 11px 1px rgba(0, 0, 0, 0.38);

  .column {
    img {
      width: auto !important;
      height: calc(var(--heightMiniaturizeHeader) - 40px);
    }

    p {
      color: black !important;
    }
  }
}
