@import '../../style/defaults.scss';

:root {
  --topSideNavNav: 100px;
}

/* Larger than desktop */
@media (min-width: 1000px) {
  :root {
    --topSideNavNav: 130px;
  }
}

#topNav,
#sideNav {
  width: clamp(10rem, 100%, 30rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;

  p {
    cursor: pointer;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 251);
    letter-spacing: 0em;
    text-decoration: none;
    letter-spacing: 0em;
    text-transform: none;
    font-size: 1.417rem;
    margin: 0;
    vertical-align: text-top;
  }
}

#sideNav {
  width: 250px;
  height: auto;
  position: fixed;
  top: var(--topSideNavNav);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  background-color: aliceblue;
  transition: left 0.2s ease-in;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 20px -20px;
  -moz-box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 20px -20px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 20px -20px;
  right: -250px;
  transition: right 0.2s ease-in;
  border-bottom-left-radius: 16px;

  p {
    width: 100%;
    padding: 0.75rem 2rem;
    border-bottom: 1px solid #ccc;
    color: #000;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #ccc;
    }
  }
}

.show {
  overflow: hidden;
  right: 0 !important;
}
