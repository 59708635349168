/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  // margin-top: 0;
  // margin-bottom: 2rem;
  // font-weight: 300;
}
.h1 {
  font-size: 2rem;
  line-height: 0.2;
  letter-spacing: -0.1rem;

  font-weight: 400;
}
.h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}
.h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;

  font-weight: 600;
}
.h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}
.h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}
.h6 {
  font-size: 1.1rem;
  line-height: 0.25;
  letter-spacing: 0;
}
.caption,
p {
  font-size: 0.875rem;
}

/* Larger than tablet */
@media (min-width: 550px) {
  .h1 {
    font-size: clamp(2rem, 5dvw, 5.8rem);
  }
  .h2 {
    font-size: 4.2rem;
  }
  .h3 {
    font-size: 3.6rem;
  }
  .h4 {
    // font-size: 3rem;
    font-size: clamp(1.125rem, 3dvw, 1.125rem);
  }
  .h5 {
    font-size: 2.4rem;
  }
  .h6 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

p {
  margin-top: 0;
  font-size: 1.5rem;
}

// .caption {
// 	font-size: clamp(0.75rem, 1dvw, 1rem);
// 	font-weight: 400;
// }

// .h1 {
// 	font-size: clamp(2rem, 3dvw, 3rem);
// }

// .h2 {
// 	font-size: clamp(0.9rem, 2.5dvw, 2.5rem);
// }

// .h3 {
// 	font-size: clamp(1.5rem, 1.5dvw, 1.5rem);
// 	font-weight: 600;
// }

// .h4 {
// 	font-size: clamp(1.125rem, 1.125dvw, 1.125rem);
// }

// .h5 {
// 	font-size: clamp(0.75rem, 0.75dvw, 0.75rem);
// }

// .h6 {
// 	font-size: 0.5dvw;
// }
