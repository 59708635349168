:root {
  --topHamburger: 40px;
  --widthHamburger: 30px;
  --heightSpanHamburger: 3px;
  --marginSpanHamburger: 0 0;
  ---heightHamburger: 16px;
}

/* Larger than desktop */
@media (min-width: 1000px) {
  :root {
    --topHamburger: 50px;
    --widthHamburger: 50px;
    --heightSpanHamburger: 5px;
    --marginSpanHamburger: -5px 0;
    ---heightHamburger: 6px;
  }
}

.hamburger {
  position: relative;
  height: var(---heightHamburger);
  width: 20px;
  // width: var(--widthHamburger);
  // margin-top: var(--topHamburger);

  label {
    cursor: pointer;
    width: inherit;
    height: inherit;
    display: flex;
    flex: 100%;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(0);

    span {
      background-color: white;
      // height: var(--heightSpanHamburger);
      height: 2px;
      width: inherit;
      transition: width 0.3s ease;
      margin: var(--marginSpanHamburger);
    }
  }
}
