:root {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-weight: 400;

  font-size: 16px;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: rgb(13, 18, 22);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: --background-color;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 1px;
}
*::-webkit-scrollbar-thumb {
  background-color: 0;
}
