@import 'src/style/defaults.scss';

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}
.button:hover,
button:hover,
input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
.button:focus,
button:focus,
input[type='submit']:focus,
input[type='reset']:focus,
input[type='button']:focus {
  color: #333;
  border-color: #888;
  outline: 0;
}
.button.button-primary,
button.button-primary,
input[type='submit'].button-primary,
input[type='reset'].button-primary,
input[type='button'].button-primary {
  color: #fff;
  background-color: #33c3f0;
  border-color: #33c3f0;
}
.button.button-primary:hover,
button.button-primary:hover,
input[type='submit'].button-primary:hover,
input[type='reset'].button-primary:hover,
input[type='button'].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type='submit'].button-primary:focus,
input[type='reset'].button-primary:focus,
input[type='button'].button-primary:focus {
  color: #fff;
  background-color: #1eaedb;
  border-color: #1eaedb;
}

.button {
  z-index: 1;
  cursor: pointer;
  border: 2px solid white;
  height: auto;

  p {
    position: relative;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 33px;
    letter-spacing: 0em;
    color: white;
    text-align: center;
    padding: 1rem 0;
    margin-bottom: 0;
  }
}
