:root {
  --widthBrand: 140%;
  --fontSizeDescBrand: 0.6rem;
  --fontSizePhoneBrand: 35%;
}

/* Larger than mobile */
@media (min-width: 400px) {
  :root {
    --fontSizePhoneBrand: 36%;
  }
}

/* Larger than phablet */
@media (min-width: 550px) {
  :root {
    --fontSizePhoneBrand: 0.875rem;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  :root {
    --widthBrand: 100%;
    --fontSizeDescBrand: 0.875rem;
  }
}

#brand {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 1rem;
  align-items: center;
  width: min(var(--widthBrand), 100%);

  .logo {
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: auto;
    transition: all 0.2s ease-in;

    &::before {
      background-color: rgba(13, 18, 22, 1);
      content: '';
    }
  }

  .desc {
    z-index: 1;
    font-weight: 400;
    font-style: normal;
    color: rgb(255, 255, 251);
    text-decoration: none;
    letter-spacing: 0em;
    text-transform: none;
    text-align: center;
    font-size: var(--fontSizeDescBrand);
    line-height: 0px;
  }

  .phone {
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: white;
    height: auto;
    width: 100%;
    display: inline-flex;

    p {
      text-align: center;
      width: 100%;
    }
  }
}
